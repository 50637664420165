import React, { Component } from "react";

export default class About extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="about">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="row row-bottom-padded-sm animate-box"
                  data-animate-effect="fadeInLeft"
                >
                  <div className="col-md-12">
                    <div className="about-desc">
                      <span className="heading-meta">About Me</span>
                      <h2 className="colorlib-heading">Who Am I?</h2>
                      <p>
                        I am a senior software developer with 5+ years
                        experience. I started my career with .NET and MS SQL and
                        followed by React Js, Node Js, MYSQL, Azure DevOps,
                        KAFKA, Kuberantives etc. Additionally, have worked in
                        CI/CD and Agile methodology too.
                      </p>
                      <p>
                        I have done a couple of freelance projects and now have
                        started working on Angular, ReactJS and NodeJS.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="colorlib-about">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div
                className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
                data-animate-effect="fadeInLeft"
              >
                <span className="heading-meta">What I do?</span>
                <h2 className="colorlib-heading">
                  Here are some of my expertise
                </h2>
              </div>
            </div>
            <div className="row row-pt-md">
              <div className="col-md-4 text-center animate-box">
                <div className="services color-1">
                  <span className="icon">
                    <i className="icon-bulb" />
                  </span>
                  <div className="desc">
                    <h3>Web Development </h3>
                    <p>
                      Have build websites from scratch using React JS, Angular
                      JS, Node Js, HTML, CSS, JavaScript/JQuery, Bootstrap, SQL
                      Server, ASP.NET.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-center animate-box">
                <div className="services color-3">
                  <span className="icon">
                    <i className="icon-phone3" />
                  </span>
                  <div className="desc">
                    <h3>Tools & Softwares</h3>
                    <p>
                      I am using Visual Studio Code, MySQL, GitHub, Kubernetes,
                      Kafka, Postman for day to day work. We are using agile
                      methodology with Azure DevOps(Used Jira in previose
                      projects) . For freelancing I have used
                      Canva(https://www.canva.com/) website for logo and other
                      designing parts.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-center animate-box">
                <div className="services color-5">
                  <span className="icon">
                    <i className="icon-data" />
                  </span>
                  <div className="desc">
                    <h3>Web hosting</h3>
                    <p>
                      While working as a freelancer, got the opportunity to
                      learn and explore web hosting. I have experience in shared
                      hosting and now exploring into VPS.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
